import React, {Fragment, FC, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {navigate, Link} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../../src/components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import DomainWhoisTool from '../../components/scan-tools/domain-whois';
import DomainIPCheckerTool from '../../components/scan-tools/domainIPChecker';
import {Modal} from '../../components/modal';
import Button from '../../components/button';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import whoisToolImg from '../../img/home-tools/whois.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const DomainWhois: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <Fragment>
      <Layout>
        <PageHero
          title={'Domain Whois Lookup Service'}
          subtitle={'Check the whois data for any domain name with our free WHOIS lookup tool.'}
        >
          <DomainForm value={url} onSubmit={u => navigate(`/domain-whois/${encodeURIComponent(u)}`)} />
        </PageHero>
        <Section>
          {isMobile ? (
            <>
              <div className="col-md-9 tool-detail">
                <div className="row">
                  <div className="col-md-12">
                    {hasDomain && url ? (
                      <ScanCountChecker url={url} disableMonitoring>
                        <ToolToken url={url}>
                          {sessionToken => {
                            TrackToolScan('DomainWhois', url);
                            return (
                              <div className="row">
                                <div className="col-md-12">
                                  <DomainWhoisTool
                                    fullResult
                                    url={url}
                                    sessionToken={sessionToken}
                                    handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <DomainIPCheckerTool
                                    fullResult
                                    url={url}
                                    sessionToken={sessionToken}
                                    handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </ToolToken>
                      </ScanCountChecker>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-4" style={{marginBottom: '20px'}}>
                            <img src={whoisToolImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                          </div>
                          <div className="col-md-8">
                            <p>
                              Our free domain name whois lookup service allows you to gather information about any
                              domain name. When you enter a domain name into the Domain Whois Lookup Service tool search
                              bar, data from the Whois record is returned on a page displaying the current profile of
                              the domain name. Currently, we have more than <strong>1600 TLD</strong>’s in our database
                              querying from providers around the world with more constantly being added.
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              Whois records contain information about the registrar, the domain's name server, the dates
                              of registration and expiration, as well as ownership and contact information about the
                              administrative and technical contacts for a domain name.
                            </p>
                            <p>
                              Enter a domain name in the search bar above to get accurate up to the minute information
                              about age, creation, update, and expiry date in a user-friendly format, supplementing
                              whole Whois raw data and the DNS Records.
                            </p>
                            <p>
                              All results shown are captured from registries and/or registrars and are framed in
                              real-time (<em>each TLD owner controls which data is displayed in Whois record</em>).
                              These results are shown for the sole purpose of assisting you in obtaining information
                              about domain name records and for no other purpose.
                            </p>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: '30px'}}>
                          <div className="col-md-6" style={{margin: 'auto'}}>
                            <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                          </div>
                          <div className="col-md-6">
                            <div className="flex-align-center">
                              <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                              <p>
                                Harness the internet as your own data source, build your own scraping bots and leverage
                                ready made automations to delegate time consuming tasks and scale your business.
                              </p>
                              <p>No coding or PhD in programming required.</p>
                              <div>
                                <Button
                                  hexomaticCTA
                                  className="monitor_btn"
                                  onClick={() =>
                                    (window.location.href =
                                      'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                  }
                                >
                                  Get started in minutes
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {scanLimitReached && (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      <p>&nbsp;</p>
                      <Modal
                        isOpen={true}
                        modalToggle={() => {}}
                        title="LOGIN_TO_CONTINUE"
                        modalFooter={
                          <Link to="/#login" state={{goBack: true}}>
                            <button type="button" className="btn btn-secondary">
                              <Translate name="GO_TO_LOGIN_PAGE" />
                            </button>
                          </Link>
                        }
                      >
                        <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      </Modal>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <ToolTabs active="domain-whois" url={url} />
              </div>
            </>
          ) : (
            <div className="col-md-12 responsive-tools">
              <div className="col-md-3">
                <ToolTabs active="domain-whois" url={url} />
              </div>
              <div className="col-md-9 tool-detail">
                <div className="row">
                  <div className="col-md-12">
                    {hasDomain && url ? (
                      <ScanCountChecker url={url} disableMonitoring>
                        <ToolToken url={url}>
                          {sessionToken => {
                            TrackToolScan('DomainWhois', url);
                            return (
                              <div className="row">
                                <div className="col-md-12">
                                  <DomainWhoisTool
                                    fullResult
                                    url={url}
                                    sessionToken={sessionToken}
                                    handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <DomainIPCheckerTool
                                    fullResult
                                    url={url}
                                    sessionToken={sessionToken}
                                    handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </ToolToken>
                      </ScanCountChecker>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-4" style={{marginBottom: '20px'}}>
                            <img src={whoisToolImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                          </div>
                          <div className="col-md-8">
                            <p>
                              Our free domain name whois lookup service allows you to gather information about any
                              domain name. When you enter a domain name into the Domain Whois Lookup Service tool search
                              bar, data from the Whois record is returned on a page displaying the current profile of
                              the domain name. Currently, we have more than <strong>1600 TLD</strong>’s in our database
                              querying from providers around the world with more constantly being added.
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              Whois records contain information about the registrar, the domain's name server, the dates
                              of registration and expiration, as well as ownership and contact information about the
                              administrative and technical contacts for a domain name.
                            </p>
                            <p>
                              Enter a domain name in the search bar above to get accurate up to the minute information
                              about age, creation, update, and expiry date in a user-friendly format, supplementing
                              whole Whois raw data and the DNS Records.
                            </p>
                            <p>
                              All results shown are captured from registries and/or registrars and are framed in
                              real-time (<em>each TLD owner controls which data is displayed in Whois record</em>).
                              These results are shown for the sole purpose of assisting you in obtaining information
                              about domain name records and for no other purpose.
                            </p>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: '30px'}}>
                          <div className="col-md-6" style={{margin: 'auto'}}>
                            <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                          </div>
                          <div className="col-md-6">
                            <div className="flex-align-center">
                              <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                              <p>
                                Harness the internet as your own data source, build your own scraping bots and leverage
                                ready made automations to delegate time consuming tasks and scale your business.
                              </p>
                              <p>No coding or PhD in programming required.</p>
                              <div>
                                <Button
                                  hexomaticCTA
                                  className="monitor_btn"
                                  onClick={() =>
                                    (window.location.href =
                                      'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                  }
                                >
                                  Get started in minutes
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {scanLimitReached && (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      <p>&nbsp;</p>
                      <Modal
                        isOpen={true}
                        modalToggle={() => {}}
                        title="LOGIN_TO_CONTINUE"
                        modalFooter={
                          <Link to="/#login" state={{goBack: true}}>
                            <button type="button" className="btn btn-secondary">
                              <Translate name="GO_TO_LOGIN_PAGE" />
                            </button>
                          </Link>
                        }
                      >
                        <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      </Modal>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Section>
      </Layout>
    </Fragment>
  );
};

export default DomainWhois;
