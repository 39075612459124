import React, {Component} from 'react';
export {Tab} from './tab';
import {TabsProps, TabsState} from '../../types';
import './styles.scss';
// import isEqual from "lodash.isequal";

export class Tabs extends Component<TabsProps, TabsState> {
  generatedTabContent: React.ReactNode | string = '';
  generatedTabTitles: React.ReactNode | string = '';

  constructor(props: TabsProps) {
    super(props);
    this.state = {
      activeTab: this.generateActiveTab(props.children),
    };
  }

  componentWillMount() {
    this.generatedTabContent = this.generateNavContent(this.props.children, this.state.activeTab);
    this.generatedTabTitles = this.generateNavTitles(this.props.children, this.state.activeTab);
  }

  // componentWillReceiveProps(nextProps: TabsProps) {
  //   if (!isEqual(this.props.children, nextProps.children)) {
  //     this.generatedTabContent = this.generateNavContent(nextProps.children, this.state.activeTab);
  //     this.generatedTabTitles = this.generateNavTitles(nextProps.children, this.state.activeTab);
  //   }
  // }

  generateActiveTab = (children: Array<React.ReactChild>) =>
    React.Children.toArray(children).findIndex((item: any) => item.props.active === true) || 0;

  generateNavContent = (children: React.ReactNode, activeTab: number): React.ReactNode => {
    return React.Children.map(children, (child: any, i: number) => {
      if (i === activeTab) {
        return React.cloneElement(child, {
          active: true,
          vertical: this.props.vertical,
        });
      } else {
        return React.cloneElement(child, {
          vertical: this.props.vertical,
        });
      }
    });
  };

  generateNavTitles = (children: React.ReactNode, activeTab: number): React.ReactNode => {
    return React.Children.map(children, (child: any, i: number) => {
      if (typeof child.props.title === 'string') {
        return (
          <li className="nav-item">
            <a
              className={`${i === activeTab ? 'nav-link active' : 'nav-link'}`}
              data-testid={`nav-link-${i}`}
              onClick={(): void => this.clickHandler(i)}
            >
              {child.props.title}
            </a>
          </li>
        );
      } else {
        return React.cloneElement(child.props.title, {
          className: i === activeTab ? 'nav-link active' : 'nav-link',
          onClick: (): void => this.clickHandler(i),
        });
      }
    });
  };

  clickHandler = (activeTab: number): void =>
    this.setState((): TabsState => {
      this.generatedTabTitles = this.generateNavTitles(this.props.children, activeTab);
      this.generatedTabContent = this.generateNavContent(this.props.children, activeTab);
      return {
        activeTab,
      };
    });

  render() {
    return (
      <div className={this.props.vertical ? 'row' : 'developer_product_content'}>
        <div className={this.props.vertical ? (this.props.linksOnly ? 'col-md-12' : 'col-lg-3 col-md-3') : ''}>
          <ul className={this.props.vertical ? 'nav nav-tabs software_service_tab' : 'nav nav-tabs develor_tab mb-30'}>
            <li>{this.generatedTabTitles}</li>
          </ul>
        </div>
        {!this.props.linksOnly && (
          <div className={this.props.vertical ? 'col-lg-8 col-md-8' : ''}>
            <div
              className={
                this.props.vertical ? 'tab-content software_service_tab_content' : 'tab-content developer_tab_content'
              }
            >
              {this.generatedTabContent}
            </div>
          </div>
        )}
      </div>
    );
  }
}
