import React, { FunctionComponent } from "react"

import { TabProps } from "../../../types"

export const Tab: FunctionComponent<TabProps> = props => (
  <div
    className={`${
      props.active ? "active show tab-pane fade" : "tab-pane fade"
    }`}
  >
    {props.children}
  </div>
)
