import React, {FunctionComponent} from 'react';
import { Link } from 'gatsby';
import {ToolTabsPropsType} from '../../types';
import {Tab, Tabs} from '../tabbedMenu';

const ToolTabs: FunctionComponent<ToolTabsPropsType> = ({active, url}) => {
  const tabs = [
    {to: '/page-analyzer', text: 'Page Analyzer', activeKey: 'page-analyzer'},
    {to: '/domain-whois', text: 'Domain Whois', activeKey: 'domain-whois'},
    {to: '/broken-links', text: 'Broken Links', activeKey: 'broken-links'},
    {to: '/meta-tag-analyzer', text: 'Meta Tag Analyzer', activeKey: 'meta-tag-analyzer'},
    {
      to: '/stack-checker',
      text: 'Tech Stack Checker',
      activeKey: 'tech-stack',
    },
    {to: '/w3c-validator', text: 'W3C Validator', activeKey: 'w3c-check'},
    {
      to: '/ssl-certificate-checker',
      text: 'SSL Checker',
      activeKey: 'ssl-checker',
    },
    {to: '/ip-lookup', text: 'IP Lookup', activeKey: 'ip-lookup'},
    {
      to: '/page-speed-scanner',
      text: 'Page Speed Scanner',
      activeKey: 'web-metrics',
    },
    {
      to: '/uptime-monitoring',
      text: 'Uptime Monitoring',
      activeKey: 'uptime-monitoring',
      exact: true,
    },
  ];

  return (
    <Tabs key={url} vertical linksOnly>
      {tabs.map(({activeKey, text, to}) => {
        return <Tab key={to} title={<Link to={to}>{text}</Link>} active={active === activeKey} />;
      })}
    </Tabs>
  );
};

export default ToolTabs;
