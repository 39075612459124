import React, {FunctionComponent} from 'react';
import { ToolBaseResultProps } from '../../types';
import {useSubscription} from '@apollo/client';

const BaseToolWrap: FunctionComponent<ToolBaseResultProps> = (props) => {
  const { url, query, sessionToken, fullResult, FullResult, SimpleResult, onResult } = props;
  const { data } = useSubscription(query, { variables: { url, sessionToken } });

  // @ts-ignore
  const queryName = query.definitions[0].selectionSet.selections[0].name.value;
  const response = (data && data[queryName]) || {};
  const { error, message, _error } = response;
  if (error) {
    console.log(message);
  }

  if (_error) {
    props.handleScanLimitCheck && props.handleScanLimitCheck(_error.code === 'UNAUTH_USER_SCANS_LIMIT_REACHED')
  }

  // If we got result making callback for it
  if (response && response.url && onResult) {
    onResult();
  }

  const FinalComponent = fullResult ? FullResult : SimpleResult;
  return FinalComponent ? (
    <FinalComponent {...props} url={undefined} {...response} baseUrl={url} />
  ) : <></>;
}

export default BaseToolWrap;
