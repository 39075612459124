import './styles.scss';
import dayjs from 'dayjs';
import React, {Fragment} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCheckCircle,
  faExclamationCircle,
  faHourglass,
  faRedoAlt,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import {isoDateToHtmlDate, parseJSONRecords} from '../../../helpers';
import {ResultCard} from '../../result-card';
import {DomainWhoisQueryResultProps} from '../../../types';
import {Translate} from '../../translations';
import {Accordion, AccordionItem} from '../../accordion';
import {ProgressBar} from '../../progressBar';

export default ({
  url,
  fullText,
  dnsData,
  expiresAt,
  lastModified,
  registeredAt,
  _error,
}: DomainWhoisQueryResultProps) => {
  const groupedDNS = dnsData && parseJSONRecords(dnsData);
  const diff = isoDateToHtmlDate(expiresAt, 'expiresAt', undefined, 'checkValue');
  const registeredDate = dayjs(isoDateToHtmlDate(registeredAt, 'registeredAt')).format('MMM DD, YYYY');

  return (
    <Fragment>
      <div className="row">
        {!url && !_error && (
          <div className="col-md-12 text-center">
            <div className="text-center">
              <ProgressBar />
            </div>
          </div>
        )}
      </div>
      {url && (
        <div className="row">
          <div className="col-md-12 domain-whois-tool-card">
            <ResultCard
              title={<Translate name="DOMAIN_WHOIS_RESULT_TITLE" />}
              items={[
                {
                  description: (
                    <>
                      <div className="info_items">
                        <div className="info_item row">
                          <div className="name d-xs-block d-sm-flex d-md-flex d-xl-flex d-lg-flex col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faHourglass} color="#fff" />
                            </div>
                            <div>
                              <Translate name="DOMAIN_AGE" />
                            </div>
                          </div>
                          <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                            {registeredAt ? isoDateToHtmlDate(registeredAt, 'domainAge') : 'N/A'}
                          </div>
                        </div>
                        <div className="info_item row">
                          <div className="name d-xs-block d-sm-flex d-md-flex d-xl-flex d-lg-flex col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faCalendarAlt} color="#fff" />
                            </div>
                            <div>
                              <Translate name="DOMAIN_REGISTERED_AT" />
                            </div>
                          </div>
                          <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                            {registeredAt ? registeredDate : 'N/A'}
                          </div>
                        </div>
                        <div className="info_item row">
                          <div className="name d-xs-block d-sm-flex d-md-flex d-xl-flex d-lg-flex col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faRedoAlt} color="#fff" />
                            </div>
                            <div>
                              <Translate name="DOMAIN_LAST_MODIFIED" />
                            </div>
                          </div>
                          <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                            {lastModified ? isoDateToHtmlDate(lastModified, 'lastModified') : 'N/A'}
                          </div>
                        </div>
                        <div className="info_item row">
                          <div className="name d-xs-block d-sm-flex d-md-flex d-xl-flex d-lg-flex col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="icon-box">
                              <FontAwesomeIcon icon={faStopwatch} color="#fff" />
                            </div>
                            <div>
                              {diff < 0 ? 'Expired:' : diff === 0 ? 'Expires:' : <Translate name="DOMAIN_EXPIRES_AT" />}
                            </div>
                          </div>
                          <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                            {expiresAt ? isoDateToHtmlDate(expiresAt, 'expiresAt', undefined, 'cardValue') : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <Accordion>
                        <AccordionItem title="DOMAIN_INFORMATION">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="subTitle">
                                <Translate name="DOMAIN_WHOIS_RESULT_TITLE" />
                              </div>
                              <div className="info_items">
                                {fullText ? <pre>{fullText}</pre> : <Translate name="NO_RESULTS_DNS" />}
                              </div>
                            </div>
                          </div>
                        </AccordionItem>
                        <AccordionItem title="DOMAIN_DNS_INFO_RESULT_TITLE">
                          <div className="col-md-12">
                            <div className="info_items">
                              {groupedDNS && Object.keys(groupedDNS).length ? (
                                Object.keys(groupedDNS).map((recordType: any, index) => {
                                  let records = groupedDNS[recordType];
                                  if (!records) return;

                                  if (typeof records.map !== 'function') {
                                    records = [records];
                                  }

                                  return (
                                    <div className="info_item" key={index}>
                                      {
                                        <h6 className="pl-4">
                                          {typeof recordType === 'string' ? (
                                            <Translate name={recordType.toUpperCase()} />
                                          ) : (
                                            recordType.toUpperCase()
                                          )}
                                        </h6>
                                      }
                                      <div>
                                        {records.map((record: any, index: number) => {
                                          if (typeof record === 'string') {
                                            return (
                                              <p className="pl-4" key={index}>
                                                {record}
                                              </p>
                                            );
                                          }

                                          if (typeof record === 'object') {
                                            return Object.keys(record).map((rk: string, index) => (
                                              <p className="pl-4" key={index}>
                                                <strong>{rk.toUpperCase()} - </strong>
                                                {record[rk]}
                                              </p>
                                            ));
                                          }
                                        })}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <Translate name="NO_RESULTS_DNS" />
                              )}
                            </div>
                          </div>
                        </AccordionItem>
                      </Accordion>
                    </>
                  ),
                },
              ]}
              message={
                <>
                  {diff >= 1 && diff <= 30 && (
                    <div className="text-left t_color3 d-flex">
                      <div className="mr-2">
                        <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'red'}} />
                      </div>
                      <div className="f_size_14">
                        {diff >= 1 && diff <= 7 ? (
                          <Translate name="WHOIS_1_7_RED" />
                        ) : (
                          <Translate name="WHOIS_8_30_RED" />
                        )}
                      </div>
                    </div>
                  )}
                  {diff > 30 && diff <= 180 && (
                    <div className="text-left t_color3 d-flex">
                      <div className="mr-2">
                        <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'yellow'}} />
                      </div>
                      <div className="f_size_14">
                        <Translate name="WHOIS_31_180_YELLOW" />
                      </div>
                    </div>
                  )}
                  {diff > 180 && (
                    <div className="text-left t_color3 d-flex">
                      <div className="mr-2">
                        <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} />
                      </div>
                      <div className="f_size_14">
                        {diff >= 181 && diff <= 365 ? (
                          <Translate name="WHOIS_181_365_GREEN" />
                        ) : (
                          <Translate name="WHOIS_365_MORE_GREEN" />
                        )}
                      </div>
                    </div>
                  )}
                </>
              }
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
