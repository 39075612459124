import React, { FC } from "react";
import { useSubscription } from "@apollo/client";
import { GetCountry } from "../../helpers";
import { ResultCard } from "../result-card";
import { ToolResultProps } from "../../types";
import { DOMAIN_IP_CHECKER_SUBSCRIPTION } from "../../graphql/subscriptions";
import ReactCountryFlag from "react-country-flag";
import { ProgressBar } from "../progressBar";

const DomainIPCheckerTool: FC<ToolResultProps> = ({ url, sessionToken, handleScanLimitCheck }) => {
  const { data } = useSubscription(DOMAIN_IP_CHECKER_SUBSCRIPTION, {
    variables: { url, sessionToken },
  });
  const response = (data && data.ipExtractionTool) || {};

  if (response._error && response._error.code === 'UNAUTH_USER_SCANS_LIMIT_REACHED') {
    handleScanLimitCheck && handleScanLimitCheck(true)
  }

  if (!response.url && !response._error) {
    return (
      <div className="text-center">
        <ProgressBar />
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {response.resolveInfo &&
          response.resolveInfo.map((resolveInfo: any) => (
            <ResultCard
              key={resolveInfo.ip}
              title={`IP - ${resolveInfo.ip}`}
              items={[
                {
                  title: "DOMAIN_IP_CHECKER_COUNTRY",
                  description:
                    resolveInfo.country && GetCountry(resolveInfo.country) ? (
                      <div>
                        <ReactCountryFlag
                          code={resolveInfo.country.toLowerCase()}
                          svg
                        />
                        &nbsp;&nbsp;
                        {GetCountry(resolveInfo.country).name}
                      </div>
                    ) : (
                      "No Data Available"
                    ),
                },
                {
                  title: "DOMAIN_IP_CHECKER_ADDRESS",
                  description: resolveInfo.address
                    ? resolveInfo.address
                    : "No Data Available",
                },
                {
                  title: "DOMAIN_IP_CHECKER_COMPANY",
                  description: resolveInfo.company
                    ? resolveInfo.company
                    : "No Data Available",
                },
                {
                  title: "DOMAIN_IP_CHECKER_NETWORK_NAME",
                  description: resolveInfo.netname
                    ? resolveInfo.netname
                    : "No Data Available",
                },
                {
                  title: "DOMAIN_IP_CHECKER_REVERSE_DNS",
                  description:
                    resolveInfo.hostnames && resolveInfo.hostnames.length > 0
                      ? resolveInfo.hostnames.join(", ")
                      : "No Data Available",
                },
                {
                  title: "DOMAIN_IP_CHECKER_IP_RANGE",
                  description: resolveInfo.ipRange
                    ? resolveInfo.ipRange
                    : "No Data Available",
                },
                {
                  title: "DOMAIN_IP_CHECKER_OPEN_PORTS",
                  description: resolveInfo.openPorts
                    ? resolveInfo.openPorts.join(", ")
                    : "No Data Available",
                },
              ]}
            />
          ))}
      </div>
    </div>
  );
};

export default DomainIPCheckerTool;
