import React, { FunctionComponent } from 'react';
import { ToolResultProps } from '../../../types';
import {DOMAIN_WHOIS_SUBSCRIPTION} from "../../../graphql/subscriptions";
import SimpleCard from "./simple-card";
import FullResultCard from "./full-result";
import BaseToolWrap from "../BaseToolWrap";

const DomainWhoisTool: FunctionComponent<ToolResultProps> = ({ url, sessionToken, fullResult, onResult, ...rest }) => (
  <BaseToolWrap
    query={DOMAIN_WHOIS_SUBSCRIPTION}
    FullResult={FullResultCard}
    SimpleResult={SimpleCard}
    url={url}
    sessionToken={sessionToken}
    fullResult={fullResult}
    onResult={onResult}
    {...rest}
  />
);

export default DomainWhoisTool;
