import React from 'react';
import dayjs from 'dayjs';
import {DomainWhoisQueryResultProps} from '../../../types';
import {ResultCard} from '../../result-card';
import Button from '../../button';
import {Translate} from '../../translations';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faHourglass,
  faRedoAlt,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import {isoDateToHtmlDate} from '../../../helpers';
import {ProgressBar} from '../../progressBar';

export default ({url, expiresAt, lastModified, registeredAt, moreButton, _error}: DomainWhoisQueryResultProps) => {
  const diff = isoDateToHtmlDate(expiresAt, 'expiresAt', undefined, 'checkValue');
  const registeredDate = dayjs(isoDateToHtmlDate(registeredAt, 'registeredAt')).format('MMM DD, YYYY');

  return (
    <div className="row">
      <div className="col-md-12 domain-whois-tool-card">
        {!url && _error ? (
          <div className="col-md-12 text-center">
            <div className="text-center">
              <ProgressBar />
            </div>
          </div>
        ) : (
          <ResultCard
            title={
              <>
                {moreButton ? (
                  moreButton
                ) : (
                  <a
                    href="https://vxbox.hexometer.com/products/domain-registration"
                    target="_blank"
                    rel="noopener"
                    className="float-right"
                  >
                    <Button className="btn-sm" light>
                      Domain Registration
                    </Button>
                  </a>
                )}
                <Translate name="DOMAIN_WHOIS_RESULT_TITLE" />
              </>
            }
            items={[
              {
                description: (
                  <>
                    <div className="info_items">
                      <div className="info_item row">
                        <div className="name col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="icon-box">
                            <FontAwesomeIcon icon={faHourglass} color="#fff" />
                          </div>
                          <Translate name="DOMAIN_AGE" />
                        </div>
                        <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                          {registeredAt ? isoDateToHtmlDate(registeredAt, 'domainAge') : 'N/A'}
                        </div>
                      </div>
                      <div className="info_item row">
                        <div className="name col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="icon-box">
                            <FontAwesomeIcon icon={faCalendarAlt} color="#fff" />
                          </div>
                          <Translate name="DOMAIN_REGISTERED_AT" />
                        </div>
                        <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                          {registeredAt ? registeredDate : 'N/A'}
                        </div>
                      </div>
                      <div className="info_item row">
                        <div className="name col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="icon-box">
                            <FontAwesomeIcon icon={faRedoAlt} color="#fff" />
                          </div>
                          <Translate name="DOMAIN_LAST_MODIFIED" />
                        </div>
                        <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                          {lastModified ? isoDateToHtmlDate(lastModified, 'lastModified') : 'N/A'}
                        </div>
                      </div>
                      <div className="info_item row">
                        <div className="name col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="icon-box">
                            <FontAwesomeIcon icon={faStopwatch} color="#fff" />
                          </div>
                          {diff < 0 ? 'Expired:' : diff === 0 ? 'Expires:' : <Translate name="DOMAIN_EXPIRES_AT" />}
                        </div>
                        <div className="descr col-lg-6 col-md-6 col-sm-6 col-6">
                          {expiresAt ? isoDateToHtmlDate(expiresAt, 'expiresAt', undefined, 'cardValue') : 'N/A'}
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
            message={
              <>
                {diff >= 1 && diff <= 30 && (
                  <div className="text-left t_color3 d-flex">
                    <div className="mr-2">
                      <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'red'}} />
                    </div>
                    <div className="f_size_14">
                      {diff >= 1 && diff <= 7 ? (
                        <Translate name="WHOIS_1_7_RED" />
                      ) : (
                        <Translate name="WHOIS_8_30_RED" />
                      )}
                    </div>
                  </div>
                )}
                {diff > 30 && diff <= 180 && (
                  <div className="text-left t_color3 d-flex">
                    <div className="mr-2">
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'yellow'}} />
                    </div>
                    <div className="f_size_14">
                      <Translate name="WHOIS_31_180_YELLOW" />
                    </div>
                  </div>
                )}
                {diff > 180 && (
                  <div className="text-left t_color3 d-flex">
                    <div className="mr-2">
                      <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} />
                    </div>
                    <div className="f_size_14">
                      {diff >= 181 && diff <= 365 ? (
                        <Translate name="WHOIS_181_365_GREEN" />
                      ) : (
                        <Translate name="WHOIS_365_MORE_GREEN" />
                      )}
                    </div>
                  </div>
                )}
              </>
            }
          />
        )}
      </div>
    </div>
  );
};
